<template>
  <base-view
    title="Análisis de Nómina"
    description="Visualiza la información presupuestal de las partidas de nómina"
    background="bg-gradient-black-to-green"
    icon="repeat"
    header-type="overlap"
  >
    <b-modal
      id="modificarAnalisis"
      title="Modificación de importe"
      v-model="showModificacion"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :busy="modificacionIsLoading"
      dialog-class="x-modal-lg"
    >
      <b-card-body>
        <b-row class="mb-2">
          <b-col cols="12" md="6">
            <b-card
              header="Información de la clave presupuestal"
              no-body
              border-variant="primary"
              class="mb-4"
              style="height: 500px; overflow: auto;"
            >
              <b-form @submit="onSubmit" size="sm">
                <b-form-group
                  id="input-group-1"
                  label="Clave"
                  label-for="input-clave"
                >
                  <b-form-input
                    id="input-clave"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="form.clave"
                    type="text"
                    size="sm"
                    required
                    readonly
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-2"
                  label="Partida"
                  label-for="input-partida"
                >
                  <b-form-input
                    id="input-partida"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="form.partida"
                    type="text"
                    size="sm"
                    required
                    readonly
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-3"
                  label="Importe"
                  label-for="input-importe"
                >
                  <b-form-input
                    id="input-importe"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="form.importe"
                    type="text"
                    size="sm"
                    required
                    readonly
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-3"
                  label="Disponible"
                  label-for="input-disponible"
                >
                  <b-form-input
                    id="input-disponible"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="form.disponible"
                    type="text"
                    size="sm"
                    required
                    readonly
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-3"
                  label="Sin devengar"
                  label-for="input-sin-devengar"
                >
                  <b-form-input
                    id="input-sin-devengar"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="form.sin_devengar"
                    type="text"
                    size="sm"
                    required
                    readonly
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-3"
                  label="Incremento(+)/disminución(-)"
                  label-for="input-modificacion"
                >
                  <b-form-input
                    id="input-modificacion"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="form.modificacion"
                    type="text"
                    size="sm"
                    required
                    placeholder="Escriba una cantidad positiva para incremento, negativa para disminución"
                    v-b-tooltip.hover
                    title="Escriba una cantidad positiva para incremento, negativa para disminución"
                  ></b-form-input>
                </b-form-group>
                <b-button type="submit" variant="primary" size="sm">Actualizar</b-button>
              </b-form>
            </b-card>
          </b-col>
          <b-col cols="12" md="6">
            <b-card
              no-body
              header="Disponibilidad presupuestal"
              header-bg-variant="warning"
              header-text-variant="light"
              border-variant="warning"
              style="height: 500px; overflow: auto;"
            >
              <b-table
                :fields="disponibleFields"
                :items="disponibleItems"
                show-empty
                responsive
                striped
                bordered
                hover
                :busy="disponibleIsLoading"
                v-if="!disponibleIsLoading"

                style="min-height: 50vh; max-height: 500px;"
                sticky-header
                head-variant="light"
                head-row-variant="light"
                small
              >
                <template #cell(clave)="data">
                  <div class="text-primary text-center text-sm w-100">
                    {{ data.item.clave }}
                  </div>
                </template>
                <template #cell(disponible)="data">
                  <div class="text-primary text-center text-sm w-100">
                    {{ $formatNumber(data.item.disponible) }}
                  </div>
                </template>
                <template #cell(sin_devengar)="data">
                  <div class="text-primary text-center text-sm w-100">
                    {{ $formatNumber(data.item.sin_devengar) }}
                  </div>
                </template>
                <template #cell(importe)="data">
                  <div class="text-primary text-center text-sm w-100">
                    {{ $formatNumber(data.item.importe) }}
                  </div>
                </template>
                <template #cell(modificacion)="data">
                  <div class="text-primary text-center text-sm w-100">
                    {{ $formatNumber(data.item.modificacion) }}
                  </div>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
    </b-modal>
    <b-modal
      id="importarReporteSaldos"
      title="Importar reporte de saldos"
      v-model="showImportacion"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :busy="modificacionIsLoading"
      dialog-class="x-modal-sm"
    >
      <b-overlay :show="uploadingData">
        <b-form @submit.prevent="onSubmitReporte">
          <!-- Archivo layout -->
          <b-form-group>
            <template #label>
              <label class="small font-weight-bolder">
                Archivo layout
                <span class="text-danger">*</span>
              </label>
            </template>

            <b-form-file
              size="sm"
              v-model="payload.file"
              :state="Boolean(payload.file)"
              placeholder="Selecciona el archivo XAML del reporte...">
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
              </template>
            </b-form-file>
          </b-form-group>

          <!-- Sobreescribir -->
          <!--b-form-group>
            <template #label>
              <label class="small font-weight-bolder">
                Archivo previamente importado
              </label>
            </template>

            <b-form-checkbox v-model="payload.overwrite" name="overwrite" switch>
              Sobreescribir
            </b-form-checkbox>
          </b-form-group-->

          <!-- Save file -->
          <!--b-form-group>
            <template #label>
              <label class="small font-weight-bolder">
                Guardar archivo
              </label>
            </template>

            <b-form-checkbox v-model="payload.save_file" name="save_file" switch>
              Guardar archivo
            </b-form-checkbox>
          </b-form-group-->

          <!-- <loading message="Importando pólizas..."></loading> -->
          <div class="my-2 d-flex">
            <slot name="btn-cancel-place" v-bind="{ clearForm }"></slot>

            <b-button
              type="submit"
              variant="success"
              size="sm"
              class="ml-auto"
              :disabled="!isFormValid"
            >
              Subir
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </b-modal>
    <b-modal
      id="filtrarPartidas"
      title="Filtro por partidas de gasto"
      v-model="showFiltroPartidas"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :busy="modificacionIsLoading"
      dialog-class="x-modal-sm"
    >
      <b-overlay :show="uploadingData">
        <b-form @submit.prevent="onFiltrarPartidas">
          <b-form-group label="Seleccione las partidas que desee visualizar:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="selectedPartidas"
              :options="analisisPartidas"
              :aria-describedby="ariaDescribedby"
              name="selected-partidas-1"
            ></b-form-checkbox-group>
          </b-form-group>
          <div class="my-2 d-flex">
            <b-button
              variant="danger"
              size="sm"
              @click="selectedPartidas = []"
            >
              Ninguna
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              class="ml-auto"
              @click="selectedPartidas = analisisPartidas"
            >
              Todas
            </b-button>
            <b-button
              type="submit"
              variant="success"
              size="sm"
              class="ml-auto"
              :disabled="selectedPartidas.length == 0"
            >
              Filtrar
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </b-modal>
    <b-modal
      id="filtrarClaves"
      title="Filtro por claves presupuestales"
      v-model="showFiltroClaves"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :busy="modificacionIsLoading"
      dialog-class="x-modal-sm"
    >
      <b-overlay :show="uploadingData">
        <b-form @submit.prevent="onFiltrarClaves">
          <b-form-group label="Seleccione las claves que desee visualizar:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="selectedClaves"
              :options="analisisClaves"
              :aria-describedby="ariaDescribedby"
              name="selected-partidas-1"
            ></b-form-checkbox-group>
          </b-form-group>
          <div class="my-2 d-flex">
            <b-button
              variant="danger"
              size="sm"
              @click="selectedClaves = []"
            >
              Ninguna
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              class="ml-auto"
              @click="selectedClaves = analisisClaves"
            >
              Todas
            </b-button>
            <b-button
              type="submit"
              variant="success"
              size="sm"
              class="ml-auto"
              :disabled="selectedClaves.length == 0"
            >
              Filtrar
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </b-modal>
    <b-modal
      id="confirmGeneracionPropuestas"
      title="Confirmar generación"
      v-model="showConfirmGeneracionPropuestas"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :busy="modificacionIsLoading"
      dialog-class="x-modal-sm"
    >
      <b-overlay :show="uploadingData">
        <b-form @submit.prevent="onConfirmGeneracionPropuestas">
          ¿Confirma la generación de propuestas de traspaso?
          <div class="my-2 d-flex">
            <b-button
              variant="danger"
              size="sm"
              @click="showConfirmGeneracionPropuestas = false"
            >
              Cancelar
            </b-button>
            <b-button
              type="submit"
              variant="success"
              size="sm"
              class="ml-auto"
            >
              Confirmar
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </b-modal>
    <b-modal
      id="confirmEliminacionPropuestas"
      title="Confirmar eliminación"
      v-model="showConfirmEliminacionPropuestas"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :busy="modificacionIsLoading"
      dialog-class="x-modal-sm"
    >
      <b-overlay :show="uploadingData">
        <b-form @submit.prevent="onConfirmEliminacionPropuestas">
          ¿Confirma la eliminación de las propuestas de traspaso?
          <div class="my-2 d-flex">
            <b-button
              variant="danger"
              size="sm"
              @click="showConfirmEliminacionPropuestas = false"
            >
              Cancelar
            </b-button>
            <b-button
              type="submit"
              variant="success"
              size="sm"
              class="ml-auto"
            >
              Confirmar
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </b-modal>
    <b-card no-body>
      <b-card-header>
        <div class="row align-items-center">
          <b-container>
            <b-row>
                <b-col lg="1">
                  <x-periodo-fiscal-tag variant="primary" />
                </b-col>
                <b-col lg="2">
                  <b-form-group
                    label="Mes"
                    label-for="mes-select"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-input-group size="sm">
                      <b-form-select
                        id="mes-select"
                        v-model="analisisMes"
                        :options="meses"
                        :aria-describedby="ariaDescribedby"
                        :disabled="loading"
                        class="w-75"
                      >
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col lg="2">
                  <b-form-group
                    label="Quincena"
                    label-for="quincena-select"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-input-group size="sm">
                      <b-form-select
                        id="quincena-select"
                        v-model="analisisQna"
                        :options="quincenas"
                        :aria-describedby="ariaDescribedby"
                        :disabled="loading"
                        class="w-75"
                      >
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group
                    label="Fuente de Financiamiento"
                    label-for="fuente-select"
                    label-cols-sm="5"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-input-group size="sm">
                      <b-form-select
                        id="fuente-select"
                        v-model="analisisFuente"
                        :options="fuentes"
                        :aria-describedby="ariaDescribedby"
                        :disabled="loading"
                        class="w-75"
                      >
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-button class="mr-3 mb-2 mb-sm-0"
                            size="sm"
                            variant="secondary"
                            :disabled="analisisIsLoading || analisisIsDownloading"
                            @click="onShowFiltroPartidas()">Filtrar partidas</b-button>
                  <b-button class="mr-3 mb-2 mb-sm-0"
                            size="sm"
                            variant="secondary"
                            :disabled="analisisIsLoading || analisisIsDownloading"
                            @click="onShowFiltroClaves()">Filtrar claves</b-button>
                </b-col>
              </b-row>
            <b-overlay :show="loading">
              <b-card no-body class="card card-header-actions mb-3">
                <b-card-header class="d-flex justify-content-between">
                  <div class="d-flex justify-content-right align-items-right" style="font-size: 1rem">
                    <b-button class="mr-3 mb-2 mb-sm-0"
                              size="sm"
                              variant="primary"
                              :disabled="analisisIsLoading || analisisIsDownloading"
                              @click="onImportarSaldos()">Importar reporte de saldos</b-button>
                    <b-button class="mr-3 mb-2 mb-sm-0"
                              size="sm"
                              variant="warning"
                              :disabled="analisisIsLoading || analisisIsDownloading"
                              @click="onGenerarPropuesta()">Generar propuestas de traspasos</b-button>
                    <b-button class="mr-3 mb-2 mb-sm-0"
                              size="sm"
                              variant="danger"
                              :disabled="analisisIsLoading || analisisIsDownloading"
                              @click="onEliminarPropuesta()">Eliminar propuestas de traspasos</b-button>
                  </div>
                  <!-- Descarga de formatos por quincena -->
                  <div class="d-flex justify-content-left align-items-left" style="font-size: 1rem">
                    <check-authorization :requiresAuthorizations="['exportar nomina']">
                      <b-button class="mr-3 mb-2 mb-sm-0"
                                size="sm"
                                variant="success"
                                :disabled="analisisIsLoading || analisisIsDownloading"
                                @click="onDownloadSolicitud()">Solicitud presupuestal</b-button>
                    </check-authorization>
                    <check-authorization :requiresAuthorizations="['exportar nomina']">
                      <b-button class="mr-3 mb-2 mb-sm-0"
                                size="sm"
                                variant="danger"
                                :disabled="analisisIsLoading || analisisIsDownloading"
                                @click="onDownloadPoliza()">Pólizas descomprometidos</b-button>
                    </check-authorization>
                  </div>
                </b-card-header>
                <b-card-body class="p-1 px-0">
                  <div class="mr-auto">
                    <div class="d-flex justify-content-around align-items-center">
                      <loading v-if="!loading && analisisIsLoading"></loading>
                      <b-table
                        v-else
                        :fields="analisisFields"
                        :items="analisisItems"
                        show-empty
                        responsive
                        striped
                        bordered
                        hover
                        style="min-height: 50vh; max-height: 500px;"
                        sticky-header
                        head-variant="light"
                        head-row-variant="light"
                        small
                        empty-filtered-text="No se encontraron pólizas con los parámetros de búsqueda escritos"
                        :empty-text="empty_table__message"
                        :busy="analisisIsLoading || analisisIsDownloading"
                      >
                        <template #cell(clave)="data">
                          <div class="text-primary text-center text-sm w-100">
                            {{ data.item.clave }}
                          </div>
                        </template>
                        <template #cell(unidad_administrativa.nombre)="data">
                          <div class="text-primary text-left text-sm w-100">
                            {{ data.item.unidad_administrativa.nombre }}
                          </div>
                        </template>
                        <template #cell()="data">
                          <div>
                            <div
                              :class='(parseFloat(data.value.importe)>= 0 ? "text-primary" : "text-danger") + " text-right text-sm w-100"'
                              v-if="data.value.importe">
                              Importe:<br/>
                              {{ $formatNumber(data.value.importe) }}
                            </div>
                            <hr v-if="data.value.importe"/>
                            <div
                              :class='(parseFloat(data.value.disponible)>=0 ? "text-primary" : "text-danger") + " text-right text-sm w-100"'
                              v-if="data.value.disponible">
                              Disponible:<br/>
                              {{ $formatNumber(data.value.disponible) }}
                            </div>
                            <hr v-if="data.value.disponible"/>
                            <div
                              :class='(parseFloat(data.value.sin_devengar)>=0 ? "text-primary" : "text-danger") + " text-right text-sm w-100"'
                              v-if="data.value.sin_devengar">
                              Comprometido no devengado:<br/>
                              {{ $formatNumber(data.value.sin_devengar )}}
                            </div>
                            <hr v-if="data.value.sin_devengar"/>
                            <div v-if="parseFloat(data.value.modificacion)===0" class="text-primary text-right text-sm w-100">
                              Sin modificación:<br/>
                              <circle-icon size="1.5x" class="custom-class"></circle-icon>
                              {{ $formatNumber(data.value.modificacion )}}
                            </div>
                            <div v-if="parseFloat(data.value.modificacion)>0" class="text-success text-right text-sm w-100">
                              Incremento:<br/>
                              <arrow-up-circle-icon size="1.5x" class="custom-class"></arrow-up-circle-icon>
                              {{ $formatNumber(data.value.modificacion )}}
                            </div>
                            <div v-if="parseFloat(data.value.modificacion)<0" class="text-danger text-right text-sm w-100">
                              Decremento:<br/>
                              <arrow-down-circle-icon size="1.5x" class="custom-class"></arrow-down-circle-icon>
                              {{ $formatNumber(data.value.modificacion )}}
                            </div>
                            <hr v-if="data.value.modificacion"/>
                            <!-- Botón para propuesta -->
                            <b-button
                              v-if="data.value.importe"
                              variant="link"
                              class="center text-warning w-100"
                              size="sm"
                              @click="onShowModificacion(data.value.movimiento_id)"
                            >Modificar</b-button>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-container>
        </div>
      </b-card-header>
    </b-card>
  </base-view>
</template>

<script>
import { mapGetters } from 'vuex'
import { ArrowDownCircleIcon, ArrowUpCircleIcon, CircleIcon } from 'vue-feather-icons'

const payload = () => ({
  file: null,
  save_file: false,
  overwrite: false
})

const errors = () => ({
  rows: []
})

export default {
  name: 'PolizasRHAnalisisNominaView',

  components: {
    CircleIcon,
    ArrowUpCircleIcon,
    ArrowDownCircleIcon
  },

  data () {
    return {
      payload: payload(),
      errors: errors(),

      form: {
        clave: null,
        unidad_administrativa_nombre: null,
        partida: null,
        importe: null,
        disponible: null,
        sin_devengar: null,
        modificacion: null
      },
      analisisMes: null,
      analisisQna: null,
      analisisFuente: null,
      showAnalisis: false,
      analisisDate: null,
      analisisFields: [{ key: 'clave', label: 'Clave', thStyle: { width: '50%' } }],
      analisisPartidas: [],
      analisisClaves: [],
      analisisData: [],
      analisisItems: [],
      selectedPartidas: [],
      selectedClaves: [],
      analisisIsLoading: false,
      analisisIsDownloading: false,
      modificacionIsLoading: false,
      uploadingData: false,

      disponibleFields: ['clave', 'disponible', 'sin_devengar', 'importe', 'modificacion', 'incrementos', 'decrementos'],
      disponibleItems: [],
      disponibleIsLoading: false,

      loading: false,
      loadingSaldosModificacion: false,

      showModificacion: false,
      showImportacion: false,
      showFiltroPartidas: false,
      showFiltroClaves: false,
      showConfirmGeneracionPropuestas: false,
      showConfirmEliminacionPropuestas: false,

      showIcon: true,
      analisisId: -1,
      analisisFolio: -1,
      analisisConcepto: '',
      movimientoId: -1,
      movimientoItem: null,
      movimientoPartida: null,

      meses: null,
      quincenas: null,
      fuentes: null,

      mes: null,

      showModal: false
    }
  },

  watch: {
    analisisMes: {
      immediate: true,
      async handler (value) {
        await this.getData()
      }
    },

    analisisQna: {
      immediate: true,
      async handler (value) {
        await this.getData()
      }
    },

    analisisFuente: {
      immediate: true,
      async handler (value) {
        await this.getData()
      }
    }
  },

  created () {
    this.init()
  },

  computed: {
    ...mapGetters('UIModule', ['getMonthById']),

    empty_table__message () {
      // Deben estar configurados los parámetros de mes y quincena
      if (this.loading) return 'Inicializando parámetros de consulta...'
      if ((this.analisisMes == null) || (this.analisisQna == null)) return 'Seleccione el mes y quincena de consulta'
      return 'No se han importado los saldos correspondientes al mes y quincena seleccionados'
    },

    isFormValid () {
      return [Boolean(this.payload.file)].every(e => e)
    }
  },

  methods: {
    /**
     * Inicializa el componente para seleccionar el periodo fiscal actualmente activo
     */
    async init () {
      console.log('init')
      this.loading = true
      this.periodoActivo = await this.$getPeriodoFiscalActivo()

      this.analisisIsLoading = true
      const response_meses = await this.$store.dispatch('momentosContablesModule/analisisNominaMeses', { periodo_fiscal_id: this.periodoActivo.id })
      const response_fuentes = await this.$store.dispatch('momentosContablesModule/analisisNominaFuentes', { periodo_fiscal_id: this.periodoActivo.id })
      this.analisisIsLoading = false

      this.meses = [{ value: null, text: '-- SELECCIONAR --' }].concat(response_meses.data.meses.map(f => ({ value: f.mes, text: this.getMonthById(f.mes).value })))
      this.fuentes = [{ value: null, text: '-- TODAS --' }].concat(response_fuentes.data.fuentes_financiamiento.map(f => ({ value: f.id, text: `${f.clave} - ${f.nombre}` })))
      this.quincenas = [
        { value: null, text: '-- SELECCIONAR --' },
        { value: 1, text: 'Primera' },
        { value: 2, text: 'Segunda' },
        { value: 3, text: 'Extraordinaria' }
      ]
      // this.usuarios = [{ value: null, text: '-- TODOS --' }].concat(response.data.usuarios.map(f => ({ value: f.usuario_importa, text: `${f.nombre} ${f.primer_apellido} ${f.segundo_apellido}` })))
      this.loading = false
    },

    async getData () {
      // Deben estar configurados los parámetros de mes y quincena
      if ((this.analisisMes == null) || (this.analisisQna == null)) return

      // Obtenemos la información para la construcción de la tabla de análisis
      this.analisisIsLoading = true
      this.periodoActivo = await this.$getPeriodoFiscalActivo()
      const getval = await this.$store.dispatch('momentosContablesModule/analisisNominaByQna', { periodo_fiscal_id: this.periodoActivo.id, mes: this.analisisMes, qna: this.analisisQna, fuente_financiamiento_id: this.analisisFuente })
      this.analisisIsLoading = false

      this.analisisFields = [
        { key: 'clave', label: 'Clave' },
        { key: 'unidad_administrativa.nombre', label: 'Unidad Administrativa' }].concat(getval.partidas)
      this.analisisData = getval.movimientos
      this.analisisItems = getval.movimientos
      this.analisisPartidas = getval.partidas
      this.selectedPartidas = getval.partidas
      this.analisisClaves = this.analisisItems.map(item => item.clave)
      this.selectedClaves = this.analisisClaves
      this.$alert(getval, 'Nóminas.', 'notificationNominasAlertPlace', {
        status: getval.error ? 'danger' : 'success',
        closeOnTimeout: true
      })
    },

    async onShowModificacion (id) {
      // Abrimos ventana de modificación de propuesta
      this.movimientoId = id
      this.showModificacion = true
      // Obtenemos disponibilidad de saldos
      this.loadingSaldosModificacion = true
      this.disponibleIsLoading = true
      const getval = await this.$store.dispatch('momentosContablesModule/analisisNominaDisponibilidad', { periodo_fiscal_id: this.periodoActivo.id, mes: this.analisisMes, qna: this.analisisQna })
      this.disponibleIsLoading = false
      this.loadingSaldosModificacion = false
      this.disponibleItems = getval.data.claves

      // Buscamos los datos
      const item = this.analisisItems.find(item => item.movimientos.includes(id))
      this.movimientoItem = item
      this.form.clave = item.clave
      const itemPartida = item.partidas.find(el => el.movimiento_id === id)
      this.form.partida = itemPartida.partida
      this.form.importe = itemPartida.importe
      this.form.disponible = itemPartida.disponible
      this.form.sin_devengar = itemPartida.sin_devengar
      this.form.modificacion = itemPartida.modificacion
    },

    async onSubmit (event) {
      event.preventDefault()
      // Hacemos la actualización del importe en el backend
      const getval = await this.$store.dispatch('momentosContablesModule/updateAnalisisNominaById',
        { movimiento_id: this.movimientoId, importe: this.form.modificacion })

      if (getval.error) {
        this.$alert(getval, 'Nóminas.', 'notificationNominasAlertPlace', {
          status: 'danger'
        })
      } else {
        // Si no hay error actualizamos la información local
        this.movimientoItem[this.form.partida].modificacion = this.form.modificacion
        this.showModificacion = false
        this.showIcon = false
        this.showIcon = true
      }
    },

    onImportarSaldos () {
      // Deben estar configurados los parámetros de mes y quincena
      if ((this.analisisMes == null) || (this.analisisQna == null)) {
        this.$notify({ error: true, message: 'Debe seleccionar los parámetros de mes y quincena' }, 'Importar reporte')
        return
      }

      this.showImportacion = true
    },

    onShowFiltroPartidas () {
      // Deben estar configurados los parámetros de mes y quincena
      if ((this.analisisMes == null) || (this.analisisQna == null)) {
        this.$notify({ error: true, message: 'Debe seleccionar los parámetros de mes y quincena' }, 'Importar reporte')
        return
      }

      this.showFiltroPartidas = true
    },

    onShowFiltroClaves () {
      // Deben estar configurados los parámetros de mes y quincena
      if ((this.analisisMes == null) || (this.analisisQna == null)) {
        this.$notify({ error: true, message: 'Debe seleccionar los parámetros de mes y quincena' }, 'Importar reporte')
        return
      }

      this.showFiltroClaves = true
    },

    onFiltrarPartidas () {
      this.selectedPartidas.sort()
      this.analisisFields = [
        { key: 'clave', label: 'Clave' },
        { key: 'unidad_administrativa.nombre', label: 'Unidad Administrativa' }].concat(this.selectedPartidas)
      this.showFiltroPartidas = false
    },

    onFiltrarClaves () {
      console.log(this.selectedClaves)
      this.analisisItems = this.analisisData.filter(item => this.selectedClaves.includes(item.clave))
      console.log(this.analisisItems)
      this.showFiltroClaves = false
    },

    async onSubmitReporte () {
      this.errors = errors()

      this.uploadingData = true
      const getval = await this.$store.dispatch('momentosContablesModule/importarReporteSaldos', {
        file: this.payload.file,

        periodo_fiscal_id: this.periodoActivo.id,
        mes: this.analisisMes,
        qna: this.analisisQna
      })
      this.uploadingData = false

      //
      // Borra el formulario, exista error o no
      this.clearForm()

      if (getval.error) {
        //
        // Obtiene los errores, si es que los hay
        this.errors.rows = getval.data

        this.$alert(
          getval,
          'Importación de layout para nómina.',
          'notificationNominasSidebarAlert',
          { status: 'danger' }
        )
      }

      //
      // Muestra mensaje de éxito y emite que ha terminado el proceso
      this.showImportacion = false
      this.getData()
    },

    onGenerarPropuesta () {
      if ((this.analisisMes == null) || (this.analisisQna == null)) {
        this.$notify({ error: true, message: 'Debe seleccionar los parámetros de mes y quincena' }, 'Importar reporte')
        return
      }
      this.showConfirmGeneracionPropuestas = true
    },

    async onConfirmGeneracionPropuestas () {
      this.analisisIsLoading = true
      const getval = await this.$store.dispatch('momentosContablesModule/analisisNominaPropuesta', {
        periodo_fiscal_id: this.periodoActivo.id,
        mes: this.analisisMes,
        qna: this.analisisQna
      })
      this.analisisIsLoading = false
      if (getval.error) {
        this.$alert(
          getval,
          'Importación de layout para nómina.',
          'notificationNominasSidebarAlert',
          { status: 'danger' }
        )
      }

      this.showConfirmGeneracionPropuestas = false
      this.getData()
    },

    onEliminarPropuesta () {
      if ((this.analisisMes == null) || (this.analisisQna == null)) {
        this.$notify({ error: true, message: 'Debe seleccionar los parámetros de mes y quincena' }, 'Importar reporte')
        return
      }
      this.showConfirmEliminacionPropuestas = true
    },

    async onConfirmEliminacionPropuestas () {
      this.analisisIsLoading = true
      const getval = await this.$store.dispatch('momentosContablesModule/analisisNominaEliminarPropuesta', {
        periodo_fiscal_id: this.periodoActivo.id,
        mes: this.analisisMes,
        qna: this.analisisQna
      })
      this.analisisIsLoading = false
      if (getval.error) {
        this.$alert(
          getval,
          'Importación de layout para nómina.',
          'notificationNominasSidebarAlert',
          { status: 'danger' }
        )
      }
      this.showConfirmEliminacionPropuestas = false
      this.getData()
    },

    clearForm () {
      this.payload = payload()
      this.errors = errors()
      this.$clearAlert()
      this.showImportacion = false
    },

    async onDownloadSolicitud () {
      // Deben estar configurados los parámetros de mes y quincena
      if ((this.analisisMes == null) || (this.analisisQna == null)) {
        this.$notify({ error: true, message: 'Debe seleccionar los parámetros de mes y quincena' }, 'Descargar solicitud')
        return
      }
      if (this.analisisItems.length === 0) {
        this.$notify({ error: true, message: 'No hay información de saldos para descarga de solicitud' }, 'Descargar solicitud')
        return
      }

      this.analisisIsDownloading = true
      const getval = await this.$store.dispatch('momentosContablesModule/exportSolicitudAnalisisNominaById', {
        periodo_fiscal_id: this.periodoActivo.id,
        mes: this.analisisMes,
        qna: this.analisisQna
      })

      await this.download(getval, `solicitud_comprometido_nomina_${this.analisisMes}_${this.analisisQna}.xlsx`)
      this.analisisIsDownloading = false

      this.$alert(
        { message: `Solicitud de descarga de solicitud de comprometido de nómina ${this.analisisMes}-${this.analisisQna}.` },
        'Nóminas.',
        'notificationNominasAlertPlace',
        {
          status: 'info',
          closeOnTimeout: true
        }
      )
    },

    async onDownloadPoliza () {
      // Deben estar configurados los parámetros de mes y quincena
      if ((this.analisisMes == null) || (this.analisisQna == null)) {
        this.$notify({ error: true, message: 'Debe seleccionar los parámetros de mes y quincena' }, 'Descargar póliza')
        return
      }
      if (this.analisisItems.length === 0) {
        this.$notify({ error: true, message: 'No hay información de saldos para descarga de póliza' }, 'Descargar póliza')
        return
      }

      this.analisisIsDownloading = true
      const getval = await this.$store.dispatch('momentosContablesModule/exportPolizaAnalisisNominaById', {
        periodo_fiscal_id: this.periodoActivo.id,
        mes: this.analisisMes,
        qna: this.analisisQna
      })

      await this.download(getval, `poliza_descomprometidos_nomina_${this.analisisMes}_${this.analisisQna}.xlsx`)
      this.analisisIsDownloading = false

      this.$alert(
        { message: `Solicitud de descarga de póliza de descomprometido de nómina ${this.analisisMes}-${this.analisisQna}.` },
        'Nóminas.',
        'notificationNominasAlertPlace',
        {
          status: 'info',
          closeOnTimeout: true
        }
      )
    },

    download (data, name) {
      const blob = new Blob([data], { type: 'blob' })
      const e = document.createEvent('MouseEvents')
      const a = document.createElement('a')

      //
      // Nombre del archivo
      a.download = name

      a.href = window.URL.createObjectURL(blob)
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
      a.dispatchEvent(e)
    }
  }
}
</script>
